.popular-prod {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }
  
  .featured-heading {
    margin: 0; /* Remove default margin */
  }
  
  .view-all {
    text-decoration: none; /* Remove underline from link */
    color: red;
    font-weight: 600;
  }
  