

 .navbar-custom {
    background-color: #ff5252; /* Red */
    background-image: linear-gradient(120deg, #ff5252 0%, #b30606 100%); /* Gradient */
  }
  .navbar-custom .navbar-brand {
    color: #ffffff; /* White */
    font-weight: bold;
  }
  .navbar-custom .navbar-nav .nav-link {
    color: #ffffff; /* White */
    font-weight: bold;
  }
  .navbar-custom .navbar-nav .nav-link:hover {
    color: #ffed4a; /* Yellow */
  }
  .navbar-custom .navbar-toggler {
    border-color: #ffffff; /* White */
  }
  .navbar-custom .navbar-toggler-icon {
    color: #ffffff; /* White */
  }
  .navbar-custom .form-inline .form-control {
    background-color: #ffffff; /* White */
    border-color: transparent;
  }
  .navbar-custom .form-inline .btn {
    color: #ff5252; /* Red */
    background-color: #ffffff; /* White */
    border-color: #ffffff; /* White */
  }
  .navbar-custom .form-inline .btn:hover {
    background-color: #f2f2f2; /* Light grey */
  }
  /* Submenu background color */
  .navbar-custom .dropdown-menu {
    background-color: #ff8080; /* Light Red */
  }
  @media (max-width: 991px) {
    .navbar-custom .navbar-collapse {
      text-align: center;
    }
    .navbar-custom .navbar-nav {
      margin: 0 auto;
    }
    .navbar-custom .form-inline {
      display: flex;
      justify-content: center;
    }
    .navbar-custom .dropdown-menu {
      text-align: center;
      background-color: #ff8080; /* Light Red */
    }
  }